import React from "react"
import { createIcon } from "@chakra-ui/react"

export default createIcon({
  displayName: "MatsIcon",
  viewBox: "0 0 62 62",
  path: [
    <circle cx="31" cy="31" r="30.5" fill="#D6F2CE" stroke="#FBF7EC" />,
    <path
      fill="#DFFD89"
      stroke="#231F20"
      d="M47.358 32.094h-10.84a.045.045 0 0 1-.045-.045V20.046c0-.931-1.126-1.398-1.784-.739L15.768 38.243c-.658.658-.192 1.783.74 1.783h9.684c.025 0 .045.02.045.046V53.23c0 .931 1.126 1.397 1.785.739l20.075-20.091c.658-.659.192-1.784-.74-1.784Z"
    />,
    <path
      fill="#DFFD89"
      stroke="#231F20"
      d="M47.27 27.62H36.43a.045.045 0 0 1-.045-.045V15.572c0-.931-1.126-1.397-1.785-.738L15.68 33.769c-.659.658-.192 1.784.739 1.784h9.684c.025 0 .045.02.045.045v13.158c0 .931 1.127 1.398 1.785.739l20.075-20.09c.659-.66.192-1.785-.739-1.785Z"
    />,
    <path
      fill="#231F20"
      stroke="#231F20"
      d="M47.358 22.365h-10.84a.045.045 0 0 1-.045-.045V10.317c0-.931-1.126-1.398-1.784-.739L15.768 28.513c-.658.659-.192 1.784.74 1.784h9.684c.025 0 .045.02.045.045v13.159c0 .93 1.126 1.397 1.785.738l20.075-20.09c.658-.659.192-1.784-.74-1.784Z"
    />,
    <path
      fill="#231F20"
      d="M18.114 16.597c0-1.48 1.2-2.678 2.68-2.678-1.48 0-2.68-1.2-2.68-2.68 0 1.48-1.2 2.68-2.678 2.68a2.678 2.678 0 0 1 2.678 2.678M43.53 52.037c0-1.057.857-1.913 1.914-1.913a1.914 1.914 0 0 1-1.914-1.914 1.913 1.913 0 0 1-1.913 1.914c1.057 0 1.913.856 1.913 1.913M43.53 13.513c0-1.057.857-1.913 1.914-1.913a1.913 1.913 0 0 1-1.914-1.914 1.913 1.913 0 0 1-1.913 1.914c1.057 0 1.913.856 1.913 1.913M14.53 44.206a.766.766 0 1 1-1.53 0 .766.766 0 0 1 1.53 0M21.174 12.196a.574.574 0 1 1-1.148 0 .574.574 0 0 1 1.148 0"
    />,
  ],
})
