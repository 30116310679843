import React from "react"
import { createIcon } from "@chakra-ui/react"

export const BitcoinsStackErrorIcon = createIcon({
  displayName: "BitcoinsStackErrorIcon",
  viewBox: "0 0 57 49",
  defaultProps: {
    fill: "none",
    w: 14,
    h: 12,
  },
  path: [
    <path
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 35.667c0 3.866 6.267 7 14 7s14-3.134 14-7m-28 0c0-3.867 6.267-7 14-7s14 3.133 14 7m-28 0V41c0 3.864 6.267 7 14 7s14-3.136 14-7v-5.333M1 19c0 2.501 2.669 4.811 7 6.062 4.331 1.25 9.669 1.25 14 0 4.331-1.25 7-3.56 7-6.062 0-2.501-2.669-4.811-7-6.062-4.331-1.25-9.669-1.25-14 0-4.331 1.25-7 3.56-7 6.062m0 0v6.667M5.667 37C2.801 35.717 1 34.405 1 32.333v-6.666m0 0c0 2.072 1.801 3.383 4.667 4.666M1 33v6.333c0 2.072 1.801 3.05 4.667 4.334"
    />,
    <path
      fill="#231f20"
      d="M27.687 32.636c-1.018-.784-2.521-.653-4.129-.217l-1.225-1.15-1.181.4 1.193 1.12c-.31.105-.623.216-.934.326l-1.201-1.127-1.18.399 1.225 1.15c-.253.09-.501.178-.745.26l-.004-.003-1.629.55.797.749s.861-.305.857-.291c.478-.162.809-.05 1.006.077l1.396 1.31c.033-.01.077-.024.13-.037l-.124.042 1.955 1.837c.074.096.137.268-.196.382.023.002-.858.29-.858.29l.656.916 1.536-.52c.286-.097.571-.19.848-.282l1.24 1.163 1.179-.399-1.226-1.15c.328-.106.643-.21.948-.315l1.221 1.146 1.18-.4-1.237-1.16c1.913-.74 2.986-1.507 1.981-2.67-.809-.938-1.938-1.096-3.236-.876.464-.438.504-.947-.242-1.52zm.999 3.047c.927.87-1.689 1.62-2.488 1.891l-1.642-1.541c.8-.271 3.164-1.257 4.13-.35m-2.866-1.99c.842.792-1.349 1.408-2.016 1.634l-1.489-1.398c.666-.226 2.626-1.06 3.505-.235m-8.133-18.058c-1.018-.784-2.521-.653-4.129-.217l-1.225-1.15-1.181.4 1.193 1.12c-.31.105-.623.216-.934.326l-1.201-1.127-1.18.399 1.225 1.15c-.253.09-.501.178-.745.26l-.004-.003-1.629.55.797.749s.861-.305.857-.291c.478-.162.809-.05 1.006.077l1.396 1.31c.033-.01.077-.024.13-.037l-.124.043 1.955 1.836c.074.096.137.268-.196.382.023.002-.858.29-.858.29l.656.916 1.536-.52c.286-.097.571-.19.848-.282l1.24 1.163 1.179-.399-1.226-1.15c.328-.106.643-.21.948-.315l1.221 1.146 1.18-.4-1.237-1.16c1.913-.74 2.986-1.507 1.981-2.67-.809-.938-1.938-1.096-3.236-.876.464-.438.504-.947-.242-1.52zm.999 3.047c.927.87-1.689 1.62-2.488 1.891l-1.642-1.542c.8-.27 3.164-1.256 4.13-.35m-2.866-1.99c.842.792-1.349 1.408-2.016 1.634l-1.489-1.398c.666-.226 2.626-1.06 3.505-.235"
    />,
    <rect
      width="24"
      height="24"
      x="-57"
      y="-24"
      fill="#da1e28"
      rx="12"
      transform="scale(-1)"
    />,
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m48.333 8.667-6.666 6.666m0-6.666 6.666 6.666"
    />,
  ],
})
