import React from "react"
import { createIcon } from "@chakra-ui/react"

export const BitcoinIcon = createIcon({
  displayName: "BitcoinIcon",
  viewBox: "0 0 28 28",
  path: [
    <path
      fill="currentColor"
      d="M14 0C6.244 0 0 6.244 0 14s6.244 14 14 14 14-6.244 14-14S21.756 0 14 0m-1.834 6.746h1.326V8.9c.355-.007.711-.013 1.06-.013v-2.14h1.329v2.196c1.713.152 3.071.684 3.223 2.268.112 1.16-.37 1.855-1.14 2.25 1.268.306 2.062 1.065 1.905 2.766-.194 2.112-1.757 2.679-3.988 2.808v2.219h-1.328v-2.19c-.344.001-.696-.002-1.06-.01v2.2l-1.327-.002v-2.223c-.31-.002-.627-.007-.95-.008H9.49l.264-1.595s.982.015.965 0c.376 0 .476-.273.5-.444v-3.517l-.002-2.504c-.05-.272-.226-.586-.764-.586.016-.018-.963.002-.963.002v-1.43l1.83.002v.006c.276 0 .558-.006.846-.012zm2.16 3.713c-.326-.002-.613.014-.8.014v2.67c.749 0 3.103.177 3.103-1.334 0-1.183-1.325-1.344-2.303-1.35m.16 4.018c-.39-.003-.736.015-.96.015l-.003 2.944c.9-.001 3.723.188 3.723-1.473 0-1.3-1.587-1.479-2.76-1.486"
    />,
  ],
})
